import React, {useState} from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { format } from 'date-fns'
import Layout from "../components/layout"
import htmlDecode from "html-entities-decoder";

import { el, enGB } from 'date-fns/locale'

import searchSvg from "./../images/search-solid.svg"

import Calendar from "./../images/calendar.svg"

import Breadcrumb from "../components/Global/Breadcrumb"
import folderIcon from "../images/folder-regular.svg";

import promoBanner from "../images//mpesstodiktio-banner-1-300x300.png"

import "./../css/BlogTemplate.css"



function BlogPageTemplate(props) {
    console.log(props)
    const [search, setSearch] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    let data = props.data;

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: page.seo.canonical,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: page.seo.canonical + props.pageContext.currentPage == 1 ? `/` : `/blog/${props.pageContext.currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function sendSearch(term) {
		if (term !== "") {
			fetch('https://admin.dkaa.gr/graphql', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					query: `query ( $search: String , $language: LanguageCodeFilterEnum) { 
								posts(where: {search: $search , language: $language}) {
									edges {
										node {
											title
                                            slug
										}
									}
								}	
							}`,
					variables:
					{
						search: term,
                        language: props.pageContext.language
					}
				}),
			})
				.then(response => {
					return response.json()
				})
				.then(responseAsJson => {
					console.log(responseAsJson.data);
					setSearchResults(responseAsJson.data.posts.edges)
				})
		} else {
			setSearchResults([])
		}
	}

    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            recentPosts={data.related}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
            services={props.pageContext.servicesCategories}
            preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
        >
            <div>
                <section>
                    <Breadcrumb list={getBreadcrumb()} h1={data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName} />
                </section>
                <section>
                    <div className="container w-full m-auto my-12 md:my-24">
                        <div className="w-full flex flex-wrap">
                            <div className="w-full md:w-1/4 flex flex-wrap order-2 md:order-1 px-4">
                                <div className="relative w-full">
                                    <h3 className="side-cat-h3">
                                        {props.pageContext.language === "EL" ? "Αναζήτηση" : "Search"}
                                    </h3>
                                    <div className="flex relative">
                                        <input value={search} onChange={(e) => setSearch(e.target.value)} className="search-i" type="text" />
                                        <button onClick={() => sendSearch(search) } className="search-b">
                                            <img src={searchSvg} width={15} />
                                        </button>
                                    </div>
                                </div>
                                {(searchResults && searchResults.length > 0) && (
                                    <ul className="search-ul">
                                        { searchResults.map((sc,i) => (
                                            <li key={`search-result-${i}`}>
                                                <Link to={`${props.pageContext.language === "EN" ? "/en" : ""}/blog/${sc.node.slug}`}>
                                                    {sc.node.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <div className="relative mt-8 w-full">
                                    <h3 className="side-cat-h3">
                                        {props.pageContext.language === "EL" ? "Κατηγορίες" : "Categories"}
                                    </h3>
                                    <ul>
                                        {data.allWpCategory.nodes.map((cat, i) => {

                                            if ((cat.name !== "Blog") && (cat.name !== "blog")) {
                                                return (
                                                    <li className="pb-4" key={`cat-${i}`}>
                                                        <Link to={props.pageContext.language === "EL" ? `/blog/${cat.slug}` : `/en/blog/${cat.slug}`} className="flex items-center">
                                                            <div>
                                                                <img src={folderIcon} style={{ width: "15px" }} />
                                                            </div>
                                                            <span className="pl-2 cat-links-li">
                                                                {cat.name}
                                                            </span>
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-8">
                                    <Link to={props.pageContext.language === "EL" ? "/become-member" : "/en/become-member-en"}>
                                        <img src={promoBanner} />
                                    </Link>
                                </div>
                            </div>
                            <div className="w-full md:w-3/4 flex flex-wrap order-1 md:order-2">
                                {console.log(data.allWpPost.nodes)}
                                {data.allWpPost.nodes.map((post, i) => {
                                    return (
                                        <article className="w-full md:w-1/2 lg:w-1/3 p-4" key={`post-tab-${i}`}>
                                            <Link to={`${props.pageContext.language === "EL" ? "/blog/" : "/" + props.pageContext.language.toLowerCase() + "/blog/"}${post.slug}/`}>
                                                <div className="relative bp-article" style={{ boxShadow: "none" }}>
                                                    <div className="blog-f-img">
                                                        {(post.featuredImage && post.featuredImage.node) && (
                                                            // <GatsbyImage
                                                            //     image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                                            //     alt={post.featuredImage.node.altText}
                                                            //     className="h-full w-full bp-img-wrapper"
                                                            //     loading="lazy" />
                                                                <img src={post.featuredImage.node.sourceUrl} alt={post.featuredImage.node.altText} />

                                                        )}
                                                    </div>
                                                    <div className="  pt-2 bp-info">
                                                        <h2 style={{ height: "74px", overflow: "hidden" }}>{htmlDecode(post.title)}</h2>
                                                    </div>
                                                    <div className="w-full z-10">
                                                        <div className="flex  blog-info pt-2">
                                                            <div className="flex justify-center">
                                                                <div>
                                                                    <img src={Calendar} style={{ width: "15px", marginRight: "5px" }} />
                                                                </div>
                                                                <div className="blog-date">{props.pageContext.language === "EL" ? format(new Date(post.date), 'dd MMMM, yyyy', { locale: el }) : format(new Date(post.date), 'dd MMMM, yyyy', { locale: enGB })}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" pt-2">
                                                        <Link to={props.pageContext.language === "EL" ? `/blog/${(post.categories.nodes[0] && post.categories.nodes[0].slug)}` : `/en/blog/${(post.categories.nodes[0] && post.categories.nodes[0].slug)}`} className="flex items-center">
                                                            <div>
                                                                <img src={folderIcon} style={{ width: "15px" }} />
                                                            </div>
                                                            <span className="pl-2 blog-date">
                                                                {(post.categories.nodes[0] && post.categories.nodes[0].name)}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <div className="pt-8">
                                                        <Link className="post-btn-readmore2" to={`${props.pageContext.language === "EL" ? "/blog/" : "/" + props.pageContext.language.toLowerCase() + "/blog/"}${post.slug}/`}>
                                                            {props.pageContext.language === "EL" ? "Διαβάστε Περισσότερα" : "Read More"}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Link>
                                        </article>
                                    );
                                })}
                                <div className="flex justify-center w-full">

                                    <ul className="flex justify-center py-8 px-4">
                                        {props.pageContext.currentPage === 2 && (
                                            <li className="mr-4 relative"  >
                                                <Link
                                                    to={`${props.pageContext.language === "EL" ? "/blog/" : "/" + props.pageContext.language.toLowerCase() + "/blog/"}`}
                                                    className={"py-2 px-4 pagination-link bp-arrow"}
                                                >
                                                </Link>
                                            </li>
                                        )}

                                        {props.pageContext.currentPage > 2 && (
                                            <li className="mr-4 relative"  >
                                                <Link
                                                    to={`${props.pageContext.language === "EL" ? "/blog/" : "/" + props.pageContext.language.toLowerCase() + "/blog/"}${props.pageContext.currentPage - 1}/`}
                                                    className={"py-2 px-4 pagination-link bp-arrow"}
                                                >
                                                </Link>
                                            </li>
                                        )}

                                        {props.pageContext.numberOfPages > 1 && (Array.from({ length: props.pageContext.numberOfPages }).map((page, index) => {
                                            if ((props.pageContext.currentPage + 1 >= index) && (props.pageContext.currentPage - 1 <= index)) {
                                                return (
                                                    <li key={index} className={`mr-4 `}  >
                                                        <Link
                                                            to={index === 0 ? `${props.pageContext.language === "EL" ? "/blog/" : "/" + props.pageContext.language.toLowerCase() + "/blog/"}` : `${props.pageContext.language === "EL" ? "/blog/" : "/" + props.pageContext.language.toLowerCase() + "/blog/"}${index + 1}/`}
                                                            className={props.pageContext.currentPage === index + 1 ? "active-pagination-link py-2 px-4 pagination-link" : "py-2 px-4 pagination-link"}
                                                        >
                                                            {index + 1}
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        }))}

                                        {props.pageContext.currentPage < props.pageContext.numberOfPages && (
                                            <li className="mr-4 relative"  >
                                                <Link
                                                    to={`${props.pageContext.language === "EL" ? "/blog/" : "/" + props.pageContext.language.toLowerCase() + "/blog/"}${props.pageContext.currentPage + 1}/`}
                                                    className={"py-2 px-4 pagination-link bp-arrow-r"}
                                                >
                                                </Link>
                                            </li>
                                        )}

                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>

        </Layout>
    );
}

export default BlogPageTemplate;



export const pageQuery = graphql`query GET_BLOG_PAGE($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String, $startPoint: Int, $postsPerPage: Int,) {
    allWpPage(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    breadcrumbAcf {
         breadcrumbName
        
       }
    }

    }

    allWpCategory(limit: 100, filter: {language: {code: {eq: $language}}}){
          nodes{
            name
            slug
          }
    }

    allWpPost(skip: $startPoint, limit: $postsPerPage , sort: { fields: [date] , order: DESC }, filter: {language: {code: {eq: $language}}}) {
        nodes {
          title
          slug
          date
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
          categories{
              nodes{
                  name
                  slug
                }
              }
          }
      }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    related: allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`
